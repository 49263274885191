<template>
  <v-col
    cols="12"
    sm="12"
  >
    <users-trainees
      :key="usersStore.activeUser.id + usersStore.activeProject.id"
      :section="4"
    />
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import { createHelpers } from 'vuex-map-fields';
import UsersTrainees from '@/components/UsersTrainees';

export default {
  name: 'TraineesList',
  components: {
    UsersTrainees
  },
  mixins: [mixin],
  props: {
    section: Number,
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
